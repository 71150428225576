/**
 *    SPDX-License-Identifier: Apache-2.0
 */

import React, { Component } from "react";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import { shape, string } from "prop-types";
import { authSelectors, authOperations } from "../../state/redux/auth";
import jwt from "jsonwebtoken";

const styles = (theme) => ({
    container: {
        width: "auto",
        display: "block", // Fix IE 11 issue.
        marginLeft: theme.spacing.unit * 3,
        marginRight: theme.spacing.unit * 3,
        [theme.breakpoints.up(400 + theme.spacing.unit * 3 * 2)]: {
            width: 400,
            marginLeft: "auto",
            marginRight: "auto",
        },
    },
    paper: {
        marginTop: theme.spacing.unit * 8,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px ${theme.spacing.unit * 3}px`,
    },
    avatar: {
        margin: theme.spacing.unit,
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: "100%", // Fix IE 11 issue.
        marginTop: theme.spacing.unit,
    },
    submit: {
        marginTop: theme.spacing.unit * 3,
    },
    errortext: {
        fontSize: 16,
        font: "bold",
        color: "red",
    },
});

const obortechAppURL = "https://login.obortech.io"; //process.env.OB_URL;

export class Login extends Component {
    static propTypes = {
        classes: shape({
            avatar: string,
            form: string,
            container: string,
            paper: string,
            submit: string,
        }).isRequired,
    };

    constructor(props) {
        super(props);
        const { networks = [] } = props;
        this.state = {
            loginerror: "",
            info: null,
            user: {
                error: null,
                value: "",
            },
            password: {
                error: null,
                value: "",
            },
            network: {
                error: null,
                value: "",
                id: "",
            },
            autoLoginAttempted: false,
            error: "",
            networks,
            authEnabled: false,
            isLoading: false,
        };
    }

    componentWillReceiveProps(nextProps) {
        const { networks = [] } = nextProps;
        this.setState(() => ({
            networks,
            network: {
                error: null,
                value: networks[0].name || "",
                id: networks[0].id,
            },
            authEnabled: networks[0].authEnabled,
        }));
    }

    handleChange = (event) => {
        const { target } = event;
        const value = target.type === "checkbox" ? target.checked : target.value;
        const { name } = target;

        const newState = {
            [name]: { value },
        };
        if (name === "network") {
            const { networks } = this.state;
            newState.authEnabled = (networks.find((n) => n.name === value) || {}).authEnabled;
            newState.network.id = (networks.find((n) => n.name === value) || {}).id;
        }

        this.setState(newState);
    };

    async performLogin({ user, password, network }) {
        const { login } = this.props;
        const info = await login(
            {
                user: user,
                password: password,
            },
            network
        );
        if (info.status !== "Error") {
            this.setState(() => ({ info }));
            if (info.status === "Success") {
                const { history } = this.props;
                history.replace("/");
                return true;
            }
        } else {
            this.setState({
                loginerror: "You are not authorized to access blockchain explorer. Please contact your administrator.",
            });
        }
    }

    async componentDidUpdate() {
        const { networks, autoLoginAttempted } = this.state;
        if (networks.length === 1 && !networks[0].authEnabled && !autoLoginAttempted) {
            this.setState(() => ({
                autoLoginAttempted: true,
                loginerror: "You are not authorized to access blockchain explorer. Please contact your administrator.",
            }));
        }
    }

    async componentWillMount() {
        const { match } = this.props;
        const userdata = match.params.userdata;
        const thisClass = this;

        this.setState(() => ({
            network: {
                id: "obortech-network",
            },
            authEnabled: true,
        }));

        if (userdata) {
            const currentTimeStamp = Math.round(new Date().getTime() / 1000);
            jwt.verify(userdata, "OBORTECHBCEXPLORER@DEVTOKEN987654321", async function(err, decodedJwt) {
                if (err) console.log("jwt.verify Error", err);
                if (!!decodedJwt) {
                    const timeDiff = Math.round(currentTimeStamp - decodedJwt.createdat);

                    console.log(decodedJwt, timeDiff);

                    localStorage.removeItem("item_id", decodedJwt.item_id);

                    if (decodedJwt.organization) {
                        localStorage.setItem("organization", decodedJwt.organization);
                    }
                    if (decodedJwt.project_id) {
                        localStorage.setItem("project_id", decodedJwt.project_id);
                    }
                    if (decodedJwt.item_id) {
                        localStorage.setItem("item_id", decodedJwt.item_id);
                    }

                    if (timeDiff < 20) {
                        await thisClass.performLogin({
                            user: decodedJwt.username,
                            password: "exploreradminpw",
                            network: "obortech-network",
                        });
                        return false;
                    } else {
                        thisClass.redirectToApp();
                    }
                } else {
                    thisClass.redirectToApp();
                }
            });
        }
    }

    redirectToApp = () => {
        const projectId = localStorage.getItem("project_id");
        const itemId = localStorage.getItem("item_id");

        let webAppUrl = obortechAppURL + `/login?refer=explorer&project=${projectId}`;
        if (itemId) {
            webAppUrl = obortechAppURL + `/login?refer=explorer&project=${projectId}&item=${itemId}`;
        }

        window.location.href = webAppUrl;
        return false;
    };

    render() {
        const { loginerror } = this.state;
        const { classes } = this.props;

        return (
            <div className={classes.container}>
                {loginerror && (
                    <>
                        <br />
                        <br />
                        <br />
                        <p className="alert alert-danger text-center">{loginerror}</p>
                    </>
                )}
            </div>
        );
    }
}

const { authSelector, errorSelector, networkSelector } = authSelectors;
const mapStateToProps = (state) => {
    return {
        auth: authSelector(state),
        error: errorSelector(state),
        networks: networkSelector(state),
    };
};

const mapDispatchToProps = {
    login: authOperations.login,
};

const connectedComponent = connect(mapStateToProps, mapDispatchToProps)(Login);
export default withStyles(styles)(connectedComponent);
