/**
 *    SPDX-License-Identifier: Apache-2.0
 */

import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import { Button } from "reactstrap";
import matchSorter from "match-sorter";
import moment from "moment-timezone";
import ReactTable from "../Styled/Table";
import TransactionView from "../View/TransactionView";
import DatePicker from "../Styled/DatePicker";
import find from "lodash/find";
import BlockView from "../View/BlockView";
import { currentChannelType, getTransactionType, transactionListType, transactionType } from "../types";

moment.tz.setDefault("asia/taipei");

/* istanbul ignore next */
const styles = (theme) => {
    const { type } = theme.palette;
    const dark = type === "dark";
    return {
        dateCreatedCell: {
            textAlign: "left",
            paddingLeft: "10px",
        },
        eventNameCell: {
            textAlign: "left !important",
            padding: "8px 12px !important",
        },
        hash: {
            "&, & li": {
                overflow: "visible !important",
            },
        },
        partialHash: {
            textAlign: "center",
            position: "relative !important",
            "&:hover $lastFullHash": {
                marginLeft: -400,
            },
            "&:hover $fullHash": {
                display: "block",
                position: "absolute !important",
                padding: "4px 4px",
                backgroundColor: dark ? "#5e558e" : "#000000",
                marginTop: -30,
                marginLeft: -215,
                borderRadius: 8,
                color: "#ffffff",
                opacity: dark ? 1 : undefined,
            },
        },
        fullHash: {
            display: "none",
        },
        lastFullHash: {},
        filter: {
            width: "100%",
            textAlign: "center",
            margin: "0px !important",
        },
        filterButton: {
            opacity: 0.8,
            margin: "auto",
            width: "100% !important",
            "margin-bottom": "4px",
        },
        searchButton: {
            opacity: 0.8,
            margin: "auto",
            width: "100% !important",
            backgroundColor: dark ? undefined : "#086108",
            "margin-bottom": "4px",
        },
        filterElement: {
            textAlign: "center",
            display: "flex",
            padding: "0px !important",
            "& > div": {
                width: "100% !important",
                marginTop: 12,
            },
            "& .label": {
                margin: "20px 10px 0px 10px",
            },
        },
    };
};

export class Transactions extends Component {
    constructor(props) {
        super(props);
        this.state = {
            dialogOpen: false,
            dialogOpenBlockHash: false,
            search: false,
            to: moment(),
            orgs: [],
            options: [],
            filtered: [],
            sorted: [],
            err: false,
            from: moment("2021-04-08 00:00:00"),
            blockHash: {},
            txData: {},
            organization: "",
        };
    }

    componentDidMount() {
        const { transactionList, currentChannel } = this.props;
        this.setState({ organization: localStorage.getItem("organization") });
        const selection = {};
        transactionList.forEach((element) => {
            selection[element.blocknum] = false;
        });
        const opts = [];
        this.props.transactionByOrg.forEach((val) => {
            opts.push({ label: val.creator_msp_id, value: val.creator_msp_id });
        });
        this.setState({ selection, options: opts });
    }

    componentWillReceiveProps(nextProps) {
        if (this.state.search && nextProps.currentChannel !== this.props.currentChannel) {
            if (this.interval !== undefined) {
                clearInterval(this.interval);
            }
            this.interval = setInterval(() => {
                this.searchTransactionList(nextProps.currentChannel);
            }, 60000);
            this.searchTransactionList(nextProps.currentChannel);
        }
    }

    componentWillUnmount() {
        clearInterval(this.interVal);
    }

    handleCustomRender(selected, options) {
        if (selected.length === 0) {
            return "Select Orgs";
        }
        if (selected.length === options.length) {
            return "All Orgs Selected";
        }

        return selected.join(",");
    }

    searchTransactionList = async (channel) => {
        //Search transactions
        let query = `from=${new Date(this.state.from).toString()}&&to=${new Date(this.state.to).toString()}`;
        for (let i = 0; i < this.state.orgs.length; i++) {
            query += `&&orgs=${this.state.orgs[i]}`;
        }
        let channelhash = this.props.currentChannel;
        if (channel !== undefined) {
            channelhash = channel;
        }
        await this.props.getTransactionListSearch(channelhash, query);
        await this.props.getBlockListSearch(channelhash, query);
    };

    handleDialogOpen = async (tid) => {
        const { currentChannel, getTransaction } = this.props;
        await getTransaction(currentChannel, tid);
        this.setState({ dialogOpen: true });
    };

    handleDialogOpenBlockHash = (blockHash) => {
        const blockList = this.state.search ? this.props.blockListSearch : this.props.blockList;
        const transactionList = this.state.search ? this.props.transactionListSearch : this.props.transactionList;

        const blockData = find(blockList, (item) => item.blockhash === blockHash);
        const txData = find(transactionList, (item) => item.blockhash === blockHash);

        this.setState({
            dialogOpenBlockHash: true,
            blockHash: blockData,
            txData: txData,
        });
    };

    handleDialogCloseBlockHash = () => {
        this.setState({ dialogOpenBlockHash: false });
    };

    handleMultiSelect = (value) => {
        this.setState({ orgs: value });
    };

    handleDialogClose = () => {
        this.setState({ dialogOpen: false });
    };

    handleSearch = async () => {
        if (this.interval !== undefined) {
            clearInterval(this.interval);
        }
        this.interval = setInterval(() => {
            this.searchTransactionList();
        }, 60000);
        await this.searchTransactionList();
        this.setState({ search: true });
    };

    handleClearSearch = () => {
        this.setState({
            search: false,
            to: moment(),
            orgs: [],
            err: false,
            from: moment("2021-04-08 00:00:00"),
        });
    };

    handleEye = (row, val) => {
        const { selection } = this.state;
        const data = Object.assign({}, selection, { [row.index]: !val });
        this.setState({ selection: data });
    };

    formatDate = (date) => {
        return moment(date).format("YYYY-MM-DD HH:mm:ss") + " UTC";
    };

    render() {
        const { classes } = this.props;
        const { organization } = this.state;

        const columnHeaders = [
            {
                Header: "Date Created",
                accessor: "start_date",
                className: classes.dateCreatedCell,
                filterMethod: (filter, rows) => matchSorter(rows, filter.value, { keys: ["start_date"] }, { threshold: matchSorter.rankings.SIMPLEMATCH }),
                filterAll: true,
                // provide custom function to format props
                Cell: (props) => this.formatDate(props.value),
            },
            {
                Header: "Event Name",
                accessor: "event_name",
                className: classes.eventNameCell,
                Cell: (row) => <span title={row.value}>{row.value}</span>,
                filterMethod: (filter, rows) => matchSorter(rows, filter.value, { keys: ["event_name"] }, { threshold: matchSorter.rankings.SIMPLEMATCH }),
                filterAll: true,
            },
            {
                Header: "Submitter",
                accessor: "submitter",
                filterMethod: (filter, rows) => matchSorter(rows, filter.value, { keys: ["submitter"] }, { threshold: matchSorter.rankings.SIMPLEMATCH }),
                filterAll: true,
            },
            {
                Header: "Item Id",
                accessor: "item_id",
                filterMethod: (filter, rows) => matchSorter(rows, filter.value, { keys: ["item_id"] }, { threshold: matchSorter.rankings.SIMPLEMATCH }),
                filterAll: true,
            },
            {
                Header: "Block Hash",
                accessor: "blockhash",
                className: classes.hash,
                Cell: (row) => (
                    <span>
                        <a data-command="block-partial-hash" className={classes.partialHash} onClick={() => this.handleDialogOpenBlockHash(row.value)} href="#/" title={row.value}>
                            {row.value.slice(0, 10)}
                            {!row.value ? "" : "..."}
                        </a>
                    </span>
                ),
                filterMethod: (filter, rows) => matchSorter(rows, filter.value, { keys: ["blockhash"] }, { threshold: matchSorter.rankings.SIMPLEMATCH }),
                filterAll: true,
            },
            {
                Header: "Transaction Id",
                accessor: "txhash",
                className: classes.hash,
                Cell: (row) => (
                    <span>
                        <a data-command="transaction-partial-hash" className={classes.partialHash} onClick={() => this.handleDialogOpen(row.value)} href="#/" title={row.value}>
                            {row.value.slice(0, 10)}
                            {!row.value ? "" : "..."}
                        </a>
                    </span>
                ),
                filterMethod: (filter, rows) => matchSorter(rows, filter.value, { keys: ["txhash"] }, { threshold: matchSorter.rankings.SIMPLEMATCH }),
                filterAll: true,
            },
        ];

        const transactionList = this.state.search ? this.props.transactionListSearch : this.props.transactionList;

        //We will only sho event transactions in the list
        let filterdTransactions = [];
        transactionList.forEach((transaction) => {
            if (transaction.write_set[1]?.set[0]?.key.indexOf("EVENTSUBMISSION") === 0) {
                try {
                    let showTxn = false;
                    //Filter by organizations
                    const txValue = JSON.parse(transaction.write_set[1]?.set[0]?.value);
                    if (txValue.visible_to_orgs) {
                        const organizatoins = txValue.visible_to_orgs.split("|");
                        if (organizatoins.indexOf(organization.toLowerCase()) !== -1) {
                            showTxn = true;
                        }
                    } else {
                        showTxn = true;
                    }

                    //Filter by Project and item
                    const project_id = localStorage.getItem("project_id");
                    const item_id = localStorage.getItem("item_id");
                    if (project_id && project_id == txValue.project_id) {
                        const eventJson = JSON.parse(transaction.write_set[1]?.set[0]?.value);
                        if (showTxn) {
                            transaction.event_name = eventJson.event_name;
                            transaction.submitter = eventJson.added_by_org;
                            transaction.item_id = eventJson.item_id;
                            transaction.start_date = eventJson.start_date;

                            if (item_id) {
                                if (item_id == eventJson.item_id) {
                                    filterdTransactions.push(transaction);
                                }
                            } else {
                                filterdTransactions.push(transaction);
                            }
                        }
                    }
                } catch (err) {
                    //console.log('transaction', transaction);
                    //console.log(err);
                }
            }
        });

        const { transaction } = this.props;
        const { dialogOpen, blockHash, dialogOpenBlockHash, txData } = this.state;
        return (
            <div>
                <div className={`${classes.filter} row searchRow`}>
                    <div className={`${classes.filterElement} col-md-3`}>
                        <label className="label">From</label>
                        <DatePicker
                            id="from"
                            selected={this.state.from}
                            showTimeSelect
                            timeIntervals={5}
                            dateFormat="YYYY-MM-DD HH:mm:ss"
                            onChange={(date) => {
                                if (date > this.state.to) {
                                    this.setState({ err: true, from: date });
                                } else {
                                    this.setState({ from: date, err: false });
                                }
                            }}
                        />
                    </div>
                    <div className={`${classes.filterElement} col-md-3`}>
                        <label className="label">To</label>
                        <DatePicker
                            id="to"
                            selected={this.state.to}
                            showTimeSelect
                            timeIntervals={5}
                            dateFormat="YYYY-MM-DD HH:mm:ss"
                            onChange={(date) => {
                                if (date > this.state.from) {
                                    this.setState({ to: date, err: false });
                                } else {
                                    this.setState({ err: true, to: date });
                                }
                            }}
                        >
                            <div className="validator ">{this.state.err && <span className=" label border-red"> From date should be less than To date</span>}</div>
                        </DatePicker>
                    </div>
                    <div className="col-md-2">
                        <Button
                            className={classes.searchButton}
                            color="success"
                            disabled={this.state.err}
                            onClick={async () => {
                                await this.handleSearch();
                            }}
                        >
                            Search
                        </Button>
                    </div>
                    <div className="col-md-1 pl-0">
                        <Button
                            className={classes.filterButton}
                            color="primary"
                            onClick={() => {
                                this.handleClearSearch();
                            }}
                        >
                            Reset
                        </Button>
                    </div>
                    <div className="col-md-1 pl-0">
                        <Button className={classes.filterButton} color="secondary" onClick={() => this.setState({ filtered: [], sorted: [] })}>
                            Clear
                        </Button>
                    </div>
                </div>
                <ReactTable
                    data={filterdTransactions}
                    columns={columnHeaders}
                    defaultPageSize={10}
                    list
                    filterable
                    sorted={this.state.sorted}
                    onSortedChange={(sorted) => {
                        this.setState({ sorted });
                    }}
                    filtered={this.state.filtered}
                    onFilteredChange={(filtered) => {
                        this.setState({ filtered });
                    }}
                    minRows={0}
                    style={{ height: "750px" }}
                    showPagination={filterdTransactions.length >= 5}
                />

                <Dialog open={dialogOpen} onClose={this.handleDialogClose} fullWidth maxWidth="md">
                    <TransactionView transaction={transaction} onClose={this.handleDialogClose} />
                </Dialog>

                <Dialog open={dialogOpenBlockHash} onClose={this.handleDialogCloseBlockHash} fullWidth maxWidth="md">
                    <BlockView blockHash={blockHash} txData={txData} onClose={this.handleDialogCloseBlockHash} />
                </Dialog>
            </div>
        );
    }
}

Transactions.propTypes = {
    currentChannel: currentChannelType.isRequired,
    getTransaction: getTransactionType.isRequired,
    transaction: transactionType,
    transactionList: transactionListType.isRequired,
};

Transactions.defaultProps = {
    transaction: null,
};

export default withStyles(styles)(Transactions);
