/**
 *    SPDX-License-Identifier: Apache-2.0
 */

import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import FontAwesome from 'react-fontawesome';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { Table, Card, CardBody, CardTitle } from 'reactstrap';
import { blockHashType, onCloseType } from '../types';
import Modal from '../Styled/Modal';
import moment from 'moment-timezone';

const styles = theme => ({
  cubeIcon: {
    color: '#ffffff',
    marginRight: 20
  }
});

export class BlockView extends Component {
  constructor(props) {
    super(props);

    this.state = {
      cargoId: '',
      eventType: '',
      timeStamp: '',
      docName: '',
      docHash: '',
      docAction: ''
    };
  }

  handleClose = () => {
    const { onClose } = this.props;
    onClose();
  };

  componentDidMount() {
    var self = this;

    const { txData } = this.props;
    const eventJson = JSON.parse(txData.write_set[1]?.set[0]?.value);

    if (eventJson) {
      self.setState({
        cargoId: eventJson.cargo_id,
        eventType: eventJson.event_name,
        timeStamp: eventJson.start_date
      });
    }

    if (eventJson.event_doc) {
      self.setState({
        docName: eventJson.event_doc.file_name,
        docHash: eventJson.event_doc.file_hash,
        docAction: eventJson.event_doc.status == 1 ? 'Added' : 'Deleted'
      });
    }
  }

  timeDataSetup = dateTime => {
    return moment(dateTime)
      .tz('Asia/Taipei')
      .format('YYYY-MM-DD HH:mm:ss');
  };

  basename = path => {
    return path.substring(path.lastIndexOf('/') + 1);
  };

  render() {
    const { blockHash, classes } = this.props;
    const {
      cargoId,
      eventType,
      docName,
      docHash,
      docAction,
      timeStamp
    } = this.state;
    if (!blockHash) {
      return (
        <Modal>
          {modalClasses => (
            <Card className={modalClasses.card}>
              <CardTitle className={modalClasses.title}>
                <FontAwesome name="cube" />
                Block Details
              </CardTitle>
              <CardBody className={modalClasses.body}>
                <span>
                  {' '}
                  <FontAwesome name="circle-o-notch" size="3x" spin />
                </span>
              </CardBody>
            </Card>
          )}
        </Modal>
      );
    }
    return (
      <Modal>
        {modalClasses => (
          <div className={modalClasses.dialog}>
            <Card className={modalClasses.card}>
              <CardTitle className={modalClasses.title}>
                <FontAwesome name="cube" className={classes.cubeIcon} />
                Block Details
                <button
                  type="button"
                  onClick={this.handleClose}
                  className={modalClasses.closeBtn}
                >
                  <FontAwesome name="close" />
                </button>
              </CardTitle>
              <CardBody className={modalClasses.body}>
                <Table striped hover responsive className="table-striped">
                  <tbody>
                    <tr>
                      <th>Channel name:</th>
                      <td>{blockHash.channelname}</td>
                    </tr>
                    <tr>
                      <th>Block Number</th>
                      <td>{blockHash.blocknum}</td>
                    </tr>
                    <tr>
                      <th>Created at</th>
                      <td>
                        {timeStamp
                          ? timeStamp
                          : this.timeDataSetup(blockHash.createdt)}
                      </td>
                    </tr>

                    <tr>
                      <th>Number of Transactions</th>
                      <td>{blockHash.txcount}</td>
                    </tr>
                    <tr>
                      <th>Block Hash</th>
                      <td>
                        {blockHash.blockhash}
                        <button type="button" className={modalClasses.copyBtn}>
                          <div className={modalClasses.copy}>Copy</div>
                          <div className={modalClasses.copied}>Copied</div>
                          <CopyToClipboard text={blockHash.blockhash}>
                            <FontAwesome name="copy" />
                          </CopyToClipboard>
                        </button>
                      </td>
                    </tr>
                    <tr>
                      <th>Data Hash</th>
                      <td>
                        {blockHash.datahash}
                        <button type="button" className={modalClasses.copyBtn}>
                          <div className={modalClasses.copy}>Copy</div>
                          <div className={modalClasses.copied}>Copied</div>
                          <CopyToClipboard text={blockHash.datahash}>
                            <FontAwesome name="copy" />
                          </CopyToClipboard>
                        </button>
                      </td>
                    </tr>
                    <tr>
                      <th>Prehash</th>
                      <td>
                        {blockHash.prehash}
                        <button type="button" className={modalClasses.copyBtn}>
                          <div className={modalClasses.copy}>Copy</div>
                          <div className={modalClasses.copied}>Copied</div>
                          <CopyToClipboard text={blockHash.prehash}>
                            <FontAwesome name="copy" />
                          </CopyToClipboard>
                        </button>
                      </td>
                    </tr>
                    {cargoId && (
                      <tr>
                        <th>Cargo ID:</th>
                        <td>{cargoId}</td>
                      </tr>
                    )}
                    {eventType && (
                      <tr>
                        <th>Event Type:</th>
                        <td>{eventType}</td>
                      </tr>
                    )}
                    {docName && (
                      <tr>
                        <th>Document Name:</th>
                        <td>
                          <a
                            rel="noopener noreferrer"
                            href={docName}
                            target="_blank"
                          >
                            {this.basename(docName)}
                          </a>
                        </td>
                      </tr>
                    )}
                    {docHash && (
                      <tr>
                        <th>Document Hash:</th>
                        <td>{docHash}</td>
                      </tr>
                    )}
                    {docAction && docName && (
                      <tr>
                        <th>Document Action:</th>
                        <td>{docAction}</td>
                      </tr>
                    )}
                  </tbody>
                </Table>
              </CardBody>
            </Card>
          </div>
        )}
      </Modal>
    );
  }
}

BlockView.propTypes = {
  blockHash: blockHashType.isRequired,
  onClose: onCloseType.isRequired
};

export default withStyles(styles)(BlockView);
